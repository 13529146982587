'use client';
import { useAppSelector } from '@/Hooks/redux';
import style from './licence.module.sass';
import Link from 'next/link';
import { NavMobileSlice } from '@/Redux/Slices/NavMobileSlice/NavMobileSlice';
import { useDispatch } from 'react-redux';
export default function Licence() {
	const { SetWindowOpen } = NavMobileSlice.actions;
	const dispatch = useDispatch();
	return (
		<section className={style.Wrapper}>
			<span className={style.txtSMI2}>ООО «СМИ2», 2024</span>
			<span className={style.txt}>
				<Link
					href={'/Terms-of-use'}
					style={{
						textDecoration: 'none'
					}}
					className={style.Links}
				>
					Соглашение
				</Link>
			</span>
			<span className={style.txt} onClick={() => dispatch(SetWindowOpen(true))}>
				О проекте
			</span>
		</section>
	);
}
