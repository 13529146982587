'use client';

//----------------------------------------
//стили
import style from '../TopHeader.module.sass';

//----------------------------------------
//  Хуки
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/Hooks/redux';

// всякие Redux приколы
import { GeoState } from '@/Redux/Slices/GeoSlice/GeoState';
import { HeadingSlice } from '@/Redux/Slices/HeadingSlice/HeadingSlice';
import { getLocation } from '@/Redux/saga/getPostLocation';
import { ModalWindowGeo } from '@/Features/ModalWindowGeo/ModalWindowGeo';

// ==================================

export default function GeoTopHeader() {
	const Location = (Geo: any): any => {
		if (Geo?.data?.length !== 0) {
			return Geo?.data?.map((el: any, ind: any) => {
				if (ind === 0) {
					return el.name;
				}
			});
		}
	};

	// штучки для Redux
	const dispatch = useDispatch();

	// Получение текуцего размера текста
	const { CurrentFontSize } = useAppSelector(state => state.HeaderState);

	// actions
	const { SetOpenModalWindow, setFinalSelect } = GeoState.actions;
	const { SetcurrentLocalId } = HeadingSlice.actions;

	// авто поиск и вывод гео
	const Geo: any = useAppSelector(state => state.getPostLocation);

	// фильтрация поиска гео
	const AllLocations: any = useAppSelector(state => state.getPostProperty);
	const GeoStateAll: any = useAppSelector(state => state.GeoState);

	// запросы и логика
	useEffect(() => {
		// запрос получения локации
		dispatch(getLocation());
	}, [dispatch]);

	useEffect(() => {
		if (GeoStateAll.isSwitcherOn === true) {
			dispatch(setFinalSelect(Location(Geo) > 0 ? Location(Geo)[0] : 'Россия'));
		} else if (GeoStateAll.isSwitcherOn === false) {
			dispatch(
				setFinalSelect(
					GeoStateAll.ClickLocal.length === 0
						? 'Россия'
						: GeoStateAll.ClickLocal
				)
			);

			if (AllLocations.data !== undefined) {
				AllLocations?.data?.locations?.map((el: any) => {
					if (el.name === GeoStateAll.FinalSelect) {
						dispatch(SetcurrentLocalId(el.id));
					}
				});
			}
		}
	}, [GeoStateAll.isSwitcherOn, GeoStateAll.ClickLocal]);

	return (
		<>
			<span
				style={{ fontSize: `${13 + CurrentFontSize}px` }}
				className={style.Geolocation}
				onClick={() => dispatch(SetOpenModalWindow())}
			>
				{GeoStateAll.isSwitcherOn
					? Location(Geo)
					: GeoStateAll.ClickLocal.length === 0
					? 'Россия'
					: GeoStateAll.ClickLocal}
			</span>
			{GeoStateAll.IsOpen && <ModalWindowGeo Geo={Geo} />}
		</>
	);
}
