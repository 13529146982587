'use client';

// всякие некст приколы
import Image from 'next/image';

//  стили
import style from '@/Features/ModalWindowGeo/ModalWindowGeo.module.sass';

//  Хуки
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/Hooks/redux';

// redux action
import { GeoState } from '@/Redux/Slices/GeoSlice/GeoState';
import { usePathname } from 'next/navigation';
import Link from 'next/link';
import { HeadingSlice } from '@/Redux/Slices/HeadingSlice/HeadingSlice';
import { TranslateFunc } from '@/lib/translate';
import { ErrorBoundary } from 'next/dist/client/components/error-boundary';

export const ModalWindowGeo = ({ Geo }: any) => {
	// action
	const { Searching, ClickLocalAct, SetOpenModalWindow, SetSwitchLocationBtn } =
		GeoState.actions;

	// проперти все города
	const AllLocations: any = useAppSelector(state => state.getPostProperty);
	// все значения внутри этого компонента
	const GeoStateAll = useAppSelector(state => state.GeoState);

	//для рендера
	const { SearchArr } = useAppSelector(state => state.GeoState);

	//-------------------------------

	// штучки для Redux
	const dispatch = useDispatch();

	// идёт ли поиск
	const [isSearchingGeo, setSearchingGeo] = useState<boolean>(false);

	const LocationsName = (AllLocations: any): any => {
		if (AllLocations?.data?.locations?.length > 1) {
			return AllLocations.data.locations.map((el: any) => el);
		}
	};

	const Location = (Geo: any): any => {
		if (Geo?.data?.length !== 0) {
			return Geo?.data?.map((el: any, ind: any) => {
				if (ind === 0) {
					return el.name;
				}
			});
		}
	};

	const pathname = usePathname();
	const Test = pathname.split('/').at(-3);

	return (
		<>
			<div className={style.modalSourcesBackground}></div>
			<div
				className={
					isSearchingGeo
						? style.modalSourcesContSearching
						: style.modalSourcesCont
				}
				onClick={() => {
					setSearchingGeo(false);
				}}
			>
				<div className={style.SourcesTitleCont}>
					<span className={style.modalSourcesTitle}>Выберите регион</span>
					<svg
						// открытие / закрытие модального окна
						onClick={() => dispatch(SetOpenModalWindow())}
						className={style.xMark}
						width='14'
						height='14'
						viewBox='0 0 14 14'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'
							fill='#686868'
						></path>
					</svg>
				</div>
				{/* <div className={style.modalFLagsCont}>
					<span className={style.CountryNameTitle}>Страна:</span>
					<div className={style.modalFLagWrapper}>
						<Image width={24} height={16} src='/RussianFlag.png' alt='' />
						<span className={style.CountryNameActive}>Россия</span>
					</div>
					<div className={style.modalFLagWrapper}>
						<Image width={24} height={16} src='/UaFlag.png' alt='' />
						<span className={style.CountryName}>Украина</span>
					</div>
					<div className={style.modalFLagWrapper}>
						<Image width={24} height={16} src='/KzFlag.png' alt='' />
						<span className={style.CountryName}>Казахстан</span>
					</div>
				</div> */}
				<div className={style.nowRegionWrapper}>
					Текущий регион:
					<span className={style.nowRegion}>
						{GeoStateAll.isSwitcherOn
							? Location(Geo)
							: GeoStateAll.ClickLocal.length === 0
							? 'Россия'
							: GeoStateAll.ClickLocal}
					</span>
				</div>
				<div className={style.modalSearchDiv}>
					<svg
						width='15'
						height='15'
						viewBox='0 0 15 15'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M10.7204 9.43396H10.0429L9.80274 9.2024C10.6432 8.2247 11.1492 6.9554 11.1492 5.57461C11.1492 2.49571 8.65352 0 5.57461 0C2.49571 0 0 2.49571 0 5.57461C0 8.65352 2.49571 11.1492 5.57461 11.1492C6.9554 11.1492 8.2247 10.6432 9.2024 9.80274L9.43396 10.0429V10.7204L13.7221 15L15 13.7221L10.7204 9.43396ZM5.57461 9.43396C3.43911 9.43396 1.71527 7.71012 1.71527 5.57461C1.71527 3.43911 3.43911 1.71527 5.57461 1.71527C7.71012 1.71527 9.43396 3.43911 9.43396 5.57461C9.43396 7.71012 7.71012 9.43396 5.57461 9.43396Z'
							fill='#979797'
						/>
					</svg>
					<input
						name='Поиск по источникам'
						placeholder='Начните вводить название региона'
						className={style.modalSearchField}
						// открытие окна списка
						onInput={() => setSearchingGeo(true)}
						// поиск по названию
						onChange={e => {
							dispatch(
								Searching({
									AllLocations: LocationsName(AllLocations),
									value: e.target.value
								})
							);
						}}
					/>
				</div>
				{isSearchingGeo ? (
					<div className={style.modalSourcesContentWrapper}>
						{SearchArr.length > 0 &&
							SearchArr.map((el: any) => (
								<span
									key={el.id}
									onClick={() => {
										// выбор города
										dispatch(ClickLocalAct(el.name));
									}}
								>
									{Test === 'region' ? (
										<Link
											className={style.LinksRest}
											href={`/region/${el.id}/${TranslateFunc(el.name)}`}
										>
											{el.name}
										</Link>
									) : (
										el.name
									)}
								</span>
							))}
					</div>
				) : (
					<div className={style.AutoGeoCont}>
						<div
							className={
								GeoStateAll.isSwitcherOn
									? style.SourceToggleOn
									: style.SourceToggleOff
							}
							onClick={() => {
								// закрывашка
								dispatch(SetSwitchLocationBtn());
							}}
						>
							<div className={style.SourceToggleSwitcher}></div>
						</div>
						<span>Определять автоматически</span>
					</div>
				)}
			</div>
		</>
	);
};
