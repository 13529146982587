'use client';

import style from './underHeader.module.sass';
import { ButtonTheme } from '@/Features/ButtonTheme/ButtonTheme';
import { Links } from '@/Features/Links/Links';

export const UnderHeader = () => {

	return (
		<div className={style.BtnCont}>
			<Links />
			<div className={style.themeCont}>
				<ButtonTheme />
			</div>
		</div>
	);
};
