'use client';

import React, { useEffect, useMemo, useCallback, FC } from 'react';
import style from './finam.module.sass';
import Link from 'next/link';
import { useAppSelector } from '@/Hooks/redux';
import { useDispatch } from 'react-redux';
import { getPostsFinams } from '@/Redux/saga/getPostFinam';
import { GlobalSvgSelector } from '@/lib/GlobalSVGSelector';

const Finam: FC = () => {
	const { theme } = useAppSelector(state => state.HeaderState);
	const dispatch = useDispatch();
	const Finam = useAppSelector(state => state.getPostsFinam);

	useEffect(() => {
		dispatch(getPostsFinams());
	}, [dispatch]);

	const truncateToTwo = useCallback(
		(num: number | undefined): string | undefined => {
			if (num !== undefined) {
				return `${(Math.floor(num * 100) / 100).toString().split('.')[0]}${
					(Math.floor(num * 100) / 100).toString().split('.')[1]
						? `,${(Math.floor(num * 100) / 100).toString().split('.')[1]}`
						: ''
				}`;
			}
			return undefined;
		},
		[]
	);

	const getCurrencyValue = useCallback(
		(name: string): number | undefined => {
			return Finam.data?.find(item => item.name === name)?.value;
		},
		[Finam.data]
	);

	const getCurrencyDelta = useCallback(
		(name: string): number | undefined => {
			return Finam.data?.find(item => item.name === name)?.delta;
		},
		[Finam.data]
	);

	const EuroValue = useMemo(
		() => getCurrencyValue('EURRUB'),
		[getCurrencyValue]
	);
	const EuroDelta = useMemo(
		() => getCurrencyDelta('EURRUB'),
		[getCurrencyDelta]
	);

	const DollarValue = useMemo(
		() => getCurrencyValue('USDRUB'),
		[getCurrencyValue]
	);
	const DollarDelta = useMemo(
		() => getCurrencyDelta('USDRUB'),
		[getCurrencyDelta]
	);

	const BrentValue = useMemo(
		() => getCurrencyValue('BRENT'),
		[getCurrencyValue]
	);
	const BrentDelta = useMemo(
		() => getCurrencyDelta('BRENT'),
		[getCurrencyDelta]
	);

	const DeltaFunction = useCallback(
		(el: number | undefined): string | undefined => {
			if (el === undefined) return '';
			return el > 0 ? `+${el}` : el < 0 ? `${el}` : '';
		},
		[]
	);

	if (Finam.Loading) {
		return (
			<div className={style.lazyLoading}>
				<div className={style.valueS}></div>
				<div className={style.valueS}></div>
				<div className={style.valueS}></div>
				<div className={style.valueS}></div>
			</div>
		);
	}
	return (
		<section className={style.FinamWrapper}>
			<ul
				className={style.UlCourse}
				// style={{ fontSize: `${13 + CurrentFontSize}px` }}
			>
				{theme === 'BLACK' ? (
					<a
						href={
							'https://news.smi2.ru/newdata/news?ad=749008&bl=4000&st=14&ct=adpreview&out=1'
						}
					>
						<GlobalSvgSelector id='finam-black' />
					</a>
				) : (
					<a
						href={
							'https://news.smi2.ru/newdata/news?ad=749008&bl=4000&st=14&ct=adpreview&out=1'
						}
					>
						<GlobalSvgSelector id='finam-white' />
					</a>
				)}

				<li>
					<GlobalSvgSelector id='dollar' />
					<div className={style.MainCourse}>
						{Finam.data?.length !== 0 ? truncateToTwo(DollarValue) : <></>}
					</div>
					{/* <div
						className={style.MainCourseUpDown}
						style={DollarDelta && DollarDelta < 0 ? { color: 'red' } : {}}
					>
						{Finam.data?.length !== 0 ? DeltaFunction(DollarDelta) : 'Loading'}
					</div> */}
				</li>
				<li>
					<GlobalSvgSelector id='euro' />
					<div className={style.MainCourse}>
						{Finam.data?.length !== 0 ? truncateToTwo(EuroValue) : <></>}
					</div>
					{/* <div
						className={style.MainCourseUpDown}
						style={EuroDelta && EuroDelta < 0 ? { color: 'red' } : {}}
					>
						{Finam.data?.length !== 0 ? DeltaFunction(EuroDelta) : 'Loading'}
					</div> */}
				</li>
				<li>
					<GlobalSvgSelector id='barel' />
					<div className={style.MainCourse}>
						{Finam.data?.length !== 0 ? truncateToTwo(BrentValue) : <></>}
					</div>
					{/* <div
						className={style.MainCourseUpDown}
						style={BrentDelta && BrentDelta < 0 ? { color: 'red' } : {}}
					>
						{Finam.data?.length !== 0 ? DeltaFunction(BrentDelta) : 'Loading'}
					</div> */}
				</li>
			</ul>
		</section>
	);
};

export default React.memo(Finam);
