import { createAction, createSlice } from '@reduxjs/toolkit';
import { put, call } from 'redux-saga/effects';
import { GetFinam } from '../Api/Fetchs/getPostFinam';
import { DataCurrency } from './Saga';

interface IPostState {
	data: Array<DataCurrency>;
	Loading: boolean;
	Error: boolean;
}
export function* getPostsFinamSaga(
	action: ReturnType<typeof getPostsFinams>
): any {
	try {
		yield put(getFinamLoading());
		const response = yield call(GetFinam);
		const payload = yield call([response, 'json']);
		yield put(getFinamSuccess(payload));
	} catch (error) {
		yield put(getFinamError(error));
	}
}

const initialState: IPostState = {
	data: [],
	Loading: true,
	Error: false
};

const getPostFinam = createSlice({
	name: 'posts',
	initialState,
	reducers: {
		getFinamLoading: state => {
			state.Loading = true;
		},
		getFinamSuccess: (state, action) => {
			state.data = action.payload;
			state.Loading = false;
		},
		getFinamError: (state, action) => {
			state.Error = action.payload;
		}
	}
});

export const GET_FINAMS = 'posts/getPostsFinam';

export const getPostsFinams = createAction(GET_FINAMS);

export const { getFinamSuccess, getFinamError, getFinamLoading } =
	getPostFinam.actions;
export default getPostFinam.reducer;
