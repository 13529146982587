'use client';
import { useAppSelector } from '@/Hooks/redux';
import style from './SelectLi.module.sass';

import { HeaderState } from '@/Redux/Slices/HeaderSlice/headerState';
import { HeadingSlice } from '@/Redux/Slices/HeadingSlice/HeadingSlice';
import { useDispatch } from 'react-redux';

import { useEffect, useState } from 'react';
import { GeoState } from '@/Redux/Slices/GeoSlice/GeoState';
import { TranslateFunc } from '@/lib/translate';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
export const SelectLi = () => {
	const dispatch = useDispatch();
	// const { currentPagePC } = useAppSelector(state => state.HeaderState);
	// const { SetcurrentPageId, SetCurrentPageName } = HeadingSlice.actions;

	const [isOpen, setOpen] = useState(false);

	const openFunction = () => {
		if (isOpen === false) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	};

	// const { Headings } = useAppSelector(state => state.HeadingSlice);

	// localNews
	const { SetcurrentLocalId } = HeadingSlice.actions;
	const Geo = useAppSelector(state => state.getPostLocation);
	const LocalNews: any = useAppSelector(state => state.getPostNewsLocal);
	const { setFinalSelect } = GeoState.actions;
	const { isSwitcherOn, FinalSelect, ClickLocal } = useAppSelector(
		state => state.GeoState
	);
	const { currentLocalId } = useAppSelector(state => state.HeadingSlice);
	const ArrNum = useAppSelector(state => state.getPostProperty);
	const [isContry, setisContry] = useState(false);
	const [stateSit, SetstateSity] = useState('');

	useEffect(() => {
		if (isSwitcherOn === true) {
			if (Geo.data.length > 0) {
				Geo.data.map((el: any, _, allArr: any) => {
					if (el.type === 'ADM1') {
						SetstateSity(el.name);
						dispatch(SetcurrentLocalId(el.geoname_id));
						dispatch(setFinalSelect(el.name));
						setisContry(false);
					}
					if (
						allArr.length == 1 &&
						el.type === 'COUNTRY' &&
						el.geoname_id === 2017370
					) {
						setisContry(true);
					}

					// SetcurrentGeoId(el.geoname_id);
				});
			}
		} else if (isSwitcherOn === false) {
			ArrNum?.data?.locations !== undefined &&
				ArrNum?.data?.locations.map((el: any) => {
					if (el.name === FinalSelect) {
						dispatch(SetcurrentLocalId(el.id));
						// SetcurrentGeoId(el.id);
						SetstateSity(el.name);
						setisContry(false);
					}
				});
		}
	}, [Geo, ArrNum, LocalNews.Loading, isSwitcherOn, FinalSelect]);

	// useEffect(() => {
	// 	if (!ArrNum.Loading && ArrNum.data.locations) {
	// 		ArrNum.data.locations.map((el: any) => {
	// 			if (el.id === currentLocalId) {
	// 				SetstateSity(el.name);
	// 			}
	// 		});
	// 	}
	// }, [isSwitcherOn]);
	const pathname = usePathname();
	const Test = pathname.split('/').at(-1);

	return (
		<div className={style.Cont}>
			<div className={style.MainCont} onClick={openFunction}>
				Eщё
				<svg
					className={isOpen ? style.arrowOpen : style.arrowDefault}
					width='10'
					height='5'
					viewBox='0 0 10 5'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path d='M0 0L5 5L10 0H0Z' fill='white' />
				</svg>
			</div>
			{isOpen ? (
				<div className={style.modalWrapper}>
					<svg
						// className={style.svgModal}
						width='20'
						height='10'
						viewBox='0 0 10 5'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M9.99963 4.99997L4.99963 -3.11136e-05L-0.000367403 4.99997L9.99963 4.99997Z'
							fill='#5A5A5A'
						/>
					</svg>
					<ul className={style.navMenu}>
						{FinalSelect.length !== 0 &&
						ClickLocal.length !== 0 &&
						!isContry ? (
							<li
								className={style.modalItem2}
								style={
									Test == `${TranslateFunc(stateSit)}`
										? { backgroundColor: '#8a8a8a' }
										: {}
								}
							>
								<Link
									className={style.LinkUnderNav}
									href={`/region/${currentLocalId}/${TranslateFunc(stateSit)}`}
								>
									{FinalSelect}
								</Link>
							</li>
						) : (
							<></>
						)}
						<li
							className={style.modalItem3}
							style={Test == '6' ? { backgroundColor: '#8a8a8a' } : {}}
						>
							<Link className={style.LinkUnderNav} href={'/topic/6'}>
								Политика
							</Link>
						</li>
						<li
							className={style.modalItem4}
							style={Test == '5' ? { backgroundColor: '#8a8a8a' } : {}}
						>
							<Link className={style.LinkUnderNav} href={'/topic/5'}>
								Спорт
							</Link>
						</li>
						<li
							className={style.modalItem5}
							style={Test == '1' ? { backgroundColor: '#8a8a8a' } : {}}
						>
							<Link className={style.LinkUnderNav} href={'/topic/1'}>
								Наука
							</Link>
						</li>
						<li
							className={style.modalItem6}
							style={Test == '3' ? { backgroundColor: '#8a8a8a' } : {}}
						>
							<Link className={style.LinkUnderNav} href={'/topic/3'}>
								Бизнес
							</Link>
						</li>
						<li
							className={style.modalItem}
							style={Test == '2' ? { backgroundColor: '#8a8a8a' } : {}}
						>
							<Link className={style.LinkUnderNav} href={'/topic/2'}>
								Технологии
							</Link>
						</li>
						<li
							className={style.modalItem}
							style={Test == '14' ? { backgroundColor: '#8a8a8a' } : {}}
						>
							<Link className={style.LinkUnderNav} href={'/topic/14'}>
								Авто и мото
							</Link>
						</li>
						<li
							className={style.modalItem}
							style={Test == '36' ? { backgroundColor: '#8a8a8a' } : {}}
						>
							<Link className={style.LinkUnderNav} href={'/topic/36'}>
								Шоу-бизнес
							</Link>
						</li>
						<li
							className={style.modalItem}
							style={Test == '3043' ? { backgroundColor: '#8a8a8a' } : {}}
						>
							<Link className={style.LinkUnderNav} href={'/publisher/3043'}>
								Разное
							</Link>
						</li>
					</ul>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};
