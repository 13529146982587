import { createAction, createSlice } from '@reduxjs/toolkit';
import { put, call } from 'redux-saga/effects';
import { getLocationFetch } from '../Api/Fetchs/getPostLocation';
import { DataLocation } from './Saga';

interface IPostState {
	data: Array<DataLocation>;
	Loading: boolean;
	Error: boolean;
}
export function* getPostLocationSaga(
	action: ReturnType<typeof getLocation>
): any {
	try {
		getPostsLocationLoading();
		const response = yield call(getLocationFetch);
		const payload = yield call([response, 'json']);
		yield put(getPostsLocationSuccess(payload));
	} catch (error) {
		yield put(getPostsLocationError(error));
	}
}

const initialState: IPostState = {
	data: [],
	Loading: true,
	Error: false
};

const getPostLocation = createSlice({
	name: 'posts',
	initialState,
	reducers: {
		getPostsLocationLoading: state => {
			state.Loading = true;
		},
		getPostsLocationSuccess: (state, action) => {
			state.data = action.payload;
			state.Loading = false;
		},
		getPostsLocationError: (state, action) => {
			state.Error = action.payload;
		}
	}
});

export const GET_LOCATION = 'article/getLocation';

export const getLocation = createAction(GET_LOCATION);

export const {
	getPostsLocationLoading,
	getPostsLocationSuccess,
	getPostsLocationError
} = getPostLocation.actions;
export default getPostLocation.reducer;
