'use client';
import { useState, useEffect } from 'react';
import style from './date.module.sass';
import Finam from '@/Features/finam/finam';
import { Suspense } from 'react';

export default function InfoLine() {
	const [date, setDate] = useState(new Date());

	useEffect(() => {
		const intervalId = setInterval(() => {
			setDate(new Date());
		}, 60000); // Обновляем время каждую минуту

		return () => clearInterval(intervalId); // Очищаем интервал при размонтировании
	}, []);

	let formatterDate = new Intl.DateTimeFormat('ru', {
		month: 'long',
		day: 'numeric'
	});
	let formatterDay = new Intl.DateTimeFormat('ru', {
		weekday: 'long'
	});

	let minuteFormatter = date.getMinutes();

	return (
		<section className={style.Wrapper}>
			<div className={style.divWrapperTime}>
				<time className={style.timeBigStroke}>
					<b>{formatterDate.format(date)}</b>,{' '}
				</time>
				<time>
					{formatterDay.format(date)}, {date.getHours()}:
					{minuteFormatter < 10 ? `0${minuteFormatter}` : minuteFormatter}
				</time>
			</div>
			<Suspense>
				<Finam />
			</Suspense>
		</section>
	);
}
