'use client';
import { useEffect, useState } from 'react';
import style from './Btn.module.sass';

export const BtnScrollUp = () => {
	const [isWindowScroll, setWindowScroll] = useState(0);
	const handlerScrollUp = () => {
		if (
			document.body.scrollTop > 500 ||
			document.documentElement.scrollTop > 500
		) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		}
	};

	const handleScroll = () => {
		setWindowScroll(window.scrollY);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<button
			className={
				isWindowScroll > 400 ? style.BtnScrollUp : style.BtnScrollUpHide
			}
			onClick={handlerScrollUp}
		>
			<svg
				width="18"
				height="12"
				viewBox="0 0 18 12"
				fill="white"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M16.9052 9.36942L15.2378 11.0268C15.1105 11.154 14.9599 11.2176 14.7857 11.2176C14.6116 11.2176 14.461 11.154 14.3337 11.0268L9.00003 5.69308L3.66633 11.0268C3.5391 11.154 3.38843 11.2176 3.21432 11.2176C3.04021 11.2176 2.88954 11.154 2.76231 11.0268L1.0949 9.36942C0.967669 9.24219 0.904053 9.08984 0.904053 8.91239C0.904053 8.73493 0.967669 8.58259 1.0949 8.45535L8.54803 1.01227C8.67526 0.885042 8.82593 0.821426 9.00003 0.821426C9.17414 0.821426 9.32481 0.885042 9.45204 1.01227L16.9052 8.45535C17.0324 8.58259 17.096 8.73493 17.096 8.91239C17.096 9.08984 17.0324 9.24219 16.9052 9.36942Z"
					fill="white"
				/>
			</svg>
		</button>
	);
};
