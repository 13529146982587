import { States } from './interfaces';

export const initialState: States = {
	IsOpen: false,
	currentSearchInput: '',
	SearchArr: [],
	ClickLocal: '',
	isSwitcherOn: true,
	FinalSelect: '',
	DeletedPublisherArr: [],
};
