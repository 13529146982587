'use client';
import { useEffect } from 'react';

export const useTheme = (theme: string) => {
	// window.mtMessenger.push([
	// 	'setOptions',
	// 	{
	// 		theme:
	// 			theme === 'WHITE' ? 'white' : theme === 'BLACK' ? 'dark' : 'WHITE',
	// 	},
	// ]);
	useEffect(() => {
		const mapColor = [
			'--main-backgroung-color',
			'--mainContent-backgroung-color',
			'--mainList-backgroung-color',
			'--LocalNews-backgroung-color',
			'--DateText-backgroung-color',
			'--OptionsButton-backgroung-color',
			'--OptionsButton-BorderBackgroung-color',
			'--OptionsButton-color',
			'--CardBorder-color',
			'--CardSwitch-active-color',
			'--CardSwitch-color',
			'--BackgroundTg-color',
			'--BackgroundBtn-color',
			'--BackgroundBtn-Hover-color',
			'--BackgroundBtnMT-color',
			'--Background-Line-color',
			'--borderBtnFont-color',
			'--BtnHot-color',
			'--Upload-backgroung-color'
		];
		mapColor.map(el => {
			const testcolor = `var(${el}-${theme})`;
			document.body.style.setProperty(el, testcolor);
		});
	}, [theme]);
};
