/* tslint:disable:object-literal-sort-keys */

const Rules = <any>{
	а: 'a',
	б: 'b',
	в: 'v',
	г: 'g',
	д: 'd',
	е: 'e',
	ё: 'yo',
	ж: 'zh',
	з: 'z',
	и: 'i',
	й: 'y',
	к: 'k',
	л: 'l',
	м: 'm',
	н: 'n',
	о: 'o',
	п: 'p',
	р: 'r',
	с: 's',
	т: 't',
	у: 'u',
	ф: 'f',
	х: 'h',
	ц: 'c',
	ч: 'ch',
	ш: 'sh',
	щ: 'shch',
	ъ: '',
	ы: 'y',
	ь: '',
	э: 'e',
	ю: 'yu',
	я: 'ya',
	' ': '-',
	1: '1',
	2: '2',
	3: '3',
	4: '4',
	5: '5',
	6: '6',
	7: '7',
	8: '8',
	9: '9',
	0: '0'
};

const returnTransliteratedString = (letterArray: any) =>
	letterArray.reduce(
		(accum: string, translittedLetter: string) =>
			`${accum}${translittedLetter}`,
		''
	);
export const TranslateFunc = (name: any) => {
	const nameSity = name
		.toLowerCase()
		.split('')
		.map((letter: any) => (Rules[letter] ? Rules[letter] : ''));

	return returnTransliteratedString(nameSity);
};
