import { States } from '@/Redux/Slices/HeadingSlice/interfaces';

export const initialState: States = {
	isOpenBurger: false,
	currentLocalId: 6255148,
	currentPageId: [5],
	CurrentPageName: 'ГЛАВНЫЕ НОВОСТИ',

	Headings: [
		[
			{ id: 1, value: 'Главное', href: '/', label: 'ГЛАВНЫЕ НОВОСТИ' },
			{
				id: 2,
				value: 'Россия-Китай',
				href: '/publisher/2957',
				label: 'РОССИЯ-КИТАЙ'
			},
			// {
			// 	id: 3,
			// 	value: 'Россия',
			// 	href: '/LocalNews',
			// 	label: 'РОССИЯ',
			// },
			{
				id: 4,
				value: 'Политика',
				href: '/topic/politics?IdTopic=6',
				label: 'ПОЛИТИКА',
				FetchingId: 6
			},
			{
				id: 5,
				value: 'Спорт',
				href: '/topic/sports?IdTopic=5',
				label: 'СПОРТ',
				FetchingId: 5
			}
		],
		[
			{
				id: 6,
				value: 'Наука',
				href: '/topic/nature-and-science?IdTopic=1',
				label: 'НАУКА',
				FetchingId: 1
			},
			{
				id: 7,
				value: 'Бизнес',
				href: '/topic/business?IdTopic=3',
				label: 'БИЗНЕС',
				FetchingId: 3
			},
			{
				id: 8,
				value: 'Технологии',
				href: '/topic/science-and-technology?IdTopic=2',
				label: 'ТЕХНОЛОГИИ',
				FetchingId: 2
			},
			{
				id: 9,
				value: 'Авто и мото',
				href: '/topic/automobile?IdTopic=14',
				label: 'АВТО И МОТО',
				FetchingId: 14
			},
			{
				id: 10,
				value: 'шоу-бизнес',
				href: '/topic/show-business?IdTopic=36',
				label: 'ШОУ-БИЗНЕС',
				FetchingId: 36
			}
		]
	]
};
