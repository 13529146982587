import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './states';
import { useSwitch } from '@/Hooks/useSwitch';

//--------------------------------------------------------------
export const GeoState = createSlice({
	name: 'GeoState',
	initialState,
	reducers: {
		setFinalSelect(state, action) {
			state.FinalSelect = action.payload;
		},

		// ---------------------
		ClickLocalAct(state, action) {
			state.ClickLocal = action.payload;
			state.isSwitcherOn = false;
		},
		SetOpenModalWindow(state) {
			state.IsOpen = useSwitch(state.IsOpen);
		},
		SetSwitchLocationBtn(state) {
			state.isSwitcherOn = useSwitch(state.isSwitcherOn);
		},
		Searching(state, action) {
			const { AllLocations, value } = action.payload;
			const regex = new RegExp(value, 'i'); // создаем регулярное выражение с флагом 'i' для игнорирования регистра
			state.SearchArr = AllLocations?.filter((obj: any) =>
				regex.test(obj.name)
			);
		}
	}
});

export default GeoState.reducer;
