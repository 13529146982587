'use client';
import { createSlice } from '@reduxjs/toolkit';

import { initialState } from '@/Redux/Slices/HeadingSlice/states';

//--------------------------------------------------------------
export const HeadingSlice = createSlice({
	name: 'HeadingSlice',
	initialState,
	reducers: {
		SetBurger(state) {
			if (state.isOpenBurger === false) {
				state.isOpenBurger = true;
			} else {
				state.isOpenBurger = false;
			}
		},
		SetTitles(state, action) {
			if (action.payload === '-') {
				if (state.Headings[0].length >= 5) {
					const Test = state.Headings[0].slice(-1);
					state.Headings[0].pop();
					state.Headings = [
						[...state.Headings[0]],
						[...Test, ...state.Headings[1]]
					];
				}
			}
			if (action.payload === '+') {
				if (state.Headings[0].length <= 6) {
					const Test = state.Headings[1].slice(0, 1);
					state.Headings[1].shift();
					state.Headings = [
						[...state.Headings[0], ...Test],
						[...state.Headings[1]]
					];
				}
			}
		},
		// SetCurrentLocation(state, action) {
		// 	// state.Headings[0].splice(1, 0, {
		// 	// 	value: action.payload,
		// 	// 	label: action.payload,
		// 	// 	href: `/LocalNews/${state.currentLocalId}`,
		// 	// });

		// 	state.Headings[0].map((el: any) => {
		// 		if (el.id === 3) {
		// 			el.value = action.payload;
		// 			el.label = action.payload;
		// 			el.href = `/LocalNews/${state.currentLocalId}`;
		// 		}
		// 	});
		// },
		SetCurrentLocationID(state, action) {
			// state.Headings[0].map((el: any) => {
			// 	if (el.id === 3) {
			// 		el.href = `/LocalNews/${action.payload}`;
			// 	}
			// });
		},

		// костыль для подгрузки топиков
		SetcurrentPageId(state, action) {
			state.currentPageId = [action.payload];
		},
		SetCurrentPageName(state, action) {
			state.CurrentPageName = action.payload;
		},
		SetcurrentLocalId(state, action) {
			state.currentLocalId = action.payload;
		}
	}
});

export default HeadingSlice.reducer;
