export const getLocationFetch = async () => {
	// /api?action=location
	// https://smi2.ru/newdata/jsapi?action=location
	return await fetch('https://smi2.ru/newdata/jsapi?action=location', {
		method: 'POST',
		cache: 'force-cache',
		next: { revalidate: 120 },
		mode: 'cors',
		body: JSON.stringify({
			block_id: 84683
		}),
		headers: new Headers({ 'content-type': 'application/json' })
	});
};
